<template>
  <div class="Page" ref="page">
    <CompanySection
      v-if="id == 'page1'"
      :height="height"
      :width="width"
      :scroll="scroll"
      :id="id"
    />
    <CustomerList v-if="id == 'page2'" />
    <ContactText
      v-if="id == 'page3'"
      :height="height"
      :width="width"
      :scroll="scroll"
      :id="id"
    />
    <FirstText v-if="id == 'first'" :width="width" />
  </div>
</template>

<script>
import CompanySection from "./CompanySection";
import ContactText from "./ContactText";
import CustomerList from "./CustomerList";
import FirstText from "./FirstText";

export default {
  components: {
    ContactText,
    CompanySection,
    CustomerList,
    FirstText,
  },
  props: ["scroll", "id"],
  created() {
    window.addEventListener("resize", this.onResize);
    // console.log(`Page ${this.id}: Created`);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    // console.log("Page: this.innerHeight " + window.innerHeight);
  },
  methods: {
    onResize() {
      if (window.innerHeight == 0) {
        //console.log(`Page ${this.id} still loading!`);
        return;
      }
      if (this.height != window.innerHeight) {
        this.height = window.innerHeight;
      }
      if (this.width != window.innerWidth) {
        this.width = window.innerWidth;
      }
    },
  },
  data: () => ({
    height: 0,
    width: 0,
  }),
};
</script>

<style >
.Page {
  width: 100vw;
}
</style>