<template>
  <a :href="url" class="customer card" target="_blank" ref="anchor">
    <div class="logoContainer">
      <div class="logo noindex noprint" ref="imgDiv">
        <img :src="src" :alt="name + ' logo'" :height="height + 'px'" />
      </div>
      <div class="logo noindex printOnly" ref="imgDivPrint">
        <img :src="src" :alt="name + ' logo (print)'" />
      </div>
    </div>
    <div class="fontSmallest">{{ name }}</div>
    <div class="print10">{{ description }}</div>
  </a>
</template>

<script>
export default {
  props: ["name", "description", "url", "image", "width", "height", "colour"],
  mounted() {
    this.src = "/css.images/" + this.image;
    const padding = 10 * 2;
    this.$refs.imgDiv.style.width = this.width + padding + ".px";
    this.$refs.imgDiv.style.height = this.height + padding + ".px";
    this.$refs.imgDiv.style.borderColor = this.colour;

    this.$refs.imgDivPrint.style.width = "74%";
    this.$refs.imgDivPrint.style.height = "auto";
    this.$refs.imgDivPrint.style.borderColor = this.colour;
  },
  data: () => ({
    src: "",
  }),
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

.customer {
  display: inline-block;
  height: auto;
  text-decoration: none;
  color: black !important;
}

@media screen {
  .customer {
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    min-height: 330px;
  }
}

@media print {
  .customer {
    margin-bottom: 6pt;
    margin-left: 3pt;
    margin-right: 3pt;
    padding: 3pt;
    page-break-before: auto;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .customer {
    height: auto;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .customer {
    min-height: 180px;
  }
}

@media screen and (max-width: $breakpoint-phone) {
  .customer {
    margin-bottom: 30px;
  }
}

.customer img {
  max-width: 100%;
  max-height: 100%;
}

.logo {
  background-color: azure;
  border: 3px solid #666666;
  padding: 10px;
  display: block;
}

.logoContainer {
  min-height: 80px;
  display: flex;
  align-items: center;
}
</style>
