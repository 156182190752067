<template>
  <div class="lastText">
    <v-overlay scroll-strategy="block" z-index="2010" v-model="showCredits">
      <LicenceCredits @close="clickBtn()" />
    </v-overlay>

    <div class="textContent fontTiny">
      <div>
        &copy; 2020-{{ year }} Corbett Technologies Pty Ltd.<br />
        ABN 34 002 868 117
      </div>
      <div class="rightAlign">
        <v-btn type="submit" icon="mdi-information-outline" @click="clickBtn()" density="compact"
          aria-label="Show licence credits" />
        <div class="version">{{ version }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import LicenceCredits from "./LicenceCredits.vue";

export default defineComponent({
  components: { LicenceCredits },
  methods: {
    clickBtn() {
      this.showCredits = !this.showCredits;
    },
  },
  setup() {
    const showCredits = ref(false);
    const version = ref("");
    const year = new Date().getFullYear();

    fetch('./version.json').then(
      (response) => {
        return response.json();
      }
    ).then(
      (data) => {
        version.value = "V" + data.version;
      }
    );

    return { showCredits, version, year };
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.lastText {
  padding: 0;
  width: 100%;
}

.textContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: $breakpoint-phone) {
  .textContent {
    margin: 0 12px;
  }
}

.rightAlign {
  text-align: right;
}

.version {
  display: inline-block;
  margin-left: 0.5rem;
}

a:link,
a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
</style>
