<template>
  <div class="page">
    <div class="content">
      <div class="alignRight">
        <v-btn
          type="submit"
          icon="mdi-close-circle-outline"
          @click="$emit('close')"
          density="compact"
          aria-label="Close licence credits"
          color="white"
          style="margin-right: -0.5rem"
        />
      </div>
      <h1>Licensed Packages Credits</h1>
      <p>This website uses one or more of the following packages:</p>
      <hr />
      <div class="panels">
        <div
          v-for="(item, ix) in licences"
          class="licencePanel"
          :key="'lic-' + ix"
        >
          <div class="spaceAfter">
            Package{{ item.packages.length > 1 ? "s" : "" }}:
            {{ item.packages.join(", ") }}
          </div>
          <div class="spaceAfter" style="display: flex">
            <span style="min-width: 15rem; margin-right: 1rem"
              >Publisher: {{ item.publisher }}</span
            >
            <span>Licence: {{ item.type }}</span>
          </div>
          <div
            v-if="item.licence != ''"
            style="white-space: pre-wrap"
            class="spaceAfter"
          >
            {{ item.licence }}
          </div>
        </div>
      </div>
      <div>
        <hr />
        <div class="footer">
          <v-btn @click="$emit('close')" aria-label="Close licence credits"
            >Close</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "licenceCredits",
  setup() {
    const licences = ref([]);
    fetch("/licences.json", {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        for (let ix = 0; ix < response.licences.length; ix++) {
          licences.value.push(response.licences[ix]);
        }
      })
      .catch((err) => {
        console.error("Error!: ", err);
      });

    return { licences };
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.alignRight {
  text-align: right;
}

button.v-btn {
  background-color: $cardBackground;
}

.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 80vw;
  max-width: 800px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 0.3rem solid $cardBorder;
  border-radius: 0.5rem;
  padding: 1rem;
  padding-top: 0.2rem;
  z-index: 2020;
}

@media screen and (max-width: $breakpoint-phone) {
  .content {
    width: 92vw;
    max-width: unset;
    height: 96vh;
  }
}
.licencePanel {
  margin: 0.5rem auto;
  padding: 1rem;
  flex-grow: 1;
  border-radius: 6px;
  border: 2px solid $cardBorder;
  font-size: smaller;
}

.panels {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 6px;
}

.spaceAfter {
  margin-bottom: 0.5rem;
}

.footer {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
