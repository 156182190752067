<template>
  <div class="customers content">
    <h2 id="ourCustomers" class="fontLarge">Our Customers</h2>
    <p class="details">
      We've had the privilege of working on many projects and organisations and
      are proud to list some of our current and past customers here.
    </p>
    <div class="grid">
      <CustomerItem v-for="(customer, idx) in customers" :key="idx" :url="customer.url" :name="customer.name"
        :description="customer.description" :image="customer.image" :colour="customer.colour" :width="customer.width"
        :height="customer.height" :class="customer.class" />
    </div>
  </div>
</template>

<script>
import CustomerItem from "./CustomerItem";
export default {
  components: {
    CustomerItem,
  },
  data: () => ({
    customers: [
      {
        name: "Volvo Group Australia",
        description: `Volvo keeps the trucks and buses running on our roads and
        we help Volvo keep their Dealer Systems running. Among other projects, we designed and installed a 
        goods receiving application that saved significant time in the warehouse,
        enabling improved customer service and inventory accuracy.`,
        url: "https://www.volvotrucks.com.au/",
        image: "volvo.png",
        colour: "#d8d7d5",
        width: 60,
        height: 60,
        class: "",
      },
      {
        name: "Department of Defence",
        description: `The mission of The Department of Defence is to defend Australia 
        and its national interests. Corbett Technologies was recently contracted to 
        assist in the protection of internal systems.`,
        url: "https://defence.gov.au/",
        image: "defence.png",
        colour: "black",
        width: 220,
        height: 60,
        class: "",
      },
      {
        name: "Australian Energy Marketing Operator (AEMO)",
        description: `AEMO are responsible for operating Australia's largest gas and electricity markets.
        We assisted AEMO on multiple projects, including system enhancements to enable the
        integration of solar, wind, battery, and pumped hydro renewable energy sources.` ,
        url: "https://www.aemo.com.au/",
        image: "aemo-logo.png",
        colour: "#360f3c",
        width: 220,
        height: 60,
        class: "",
      },
      {
        name: "NSW Health",
        description: `We have been assisting NSW Health with the development and support 
        of a workload calculator for Case Managers in several community services.`,
        url: "https://www.health.nsw.gov.au/",
        image: "NSWHealth.png",
        colour: "#d7153a",
        width: 140,
        height: 60,
        class: "",
      },
      {
        name: "Kia Manaaki Pty Ltd",
        description: `Trading as QueAccess, Kia Manaaki supplies and supports access control systems
        to a variety of industries, including hotels and self storage companies. Corbett Technologies
        designed and implemented a real time interface between source systems and hardware controllers.`,
        url: "https://www.queaccess.com.au/",
        image: "queAccess.webp",
        colour: "#0F246B",
        width: 220,
        height: 60,
        class: "",
      },
      {
        name: "AW Edwards Pty Limited",
        description: `A W Edwards is   one of the most respected construction  
        companies in Australia. Corbett Technologies designed, implemented and supported
        the job costing, accounting and payroll systems that A W Edwards used for 25 years.`,
        url: "https://awedwards.com.au/",
        image: "awedwards.gif",
        colour: "#f7403a",
        width: 220,
        height: 32,
        class: "",
      },
      {
        name: "Eggler Institute of Technology",
        description: `Eggler is a leading technical education and professional 
        development training services company specialising in capability design, 
        systems engineering and the design of military technology. We assisted
        with updates to websites and configuration of mail services.`,
        url: "https://www.eit.global",
        image: "eggler-updated-icon.svg",
        colour: "#A71930",
        width: 220,
        height: 50,
        class: "",
      },
      {
        name: "Zodiac Group Australia",
        description: `Zodiac Group Australia is the leading supplier of pool equipment
        and pool products in Australia. Corbett Technologies provided support and 
        enhancements to their corporate ERP system. We also designed and implemented a product
        warranty and service management system that integrated with this system.`,
        url: "https://www.zodiac.com.au/",
        image: "zodiac.png",
        colour: "#0061ad",
        width: 220,
        height: 50,
        class: "pageBreak",
      },
      {
        name: "12thLevel",
        description: `12thLevel (now defunct) were specialists in Defence Capability Management.
        We had been engaged with the development, support and enhancement of desktop, 
        mobile and web applications for the Australian Defence Forces 
        (including the Royal Australian Navy and 
        Joint Operations Command) as well as for the Royal New Zealand Navy.`,
        url: null,
        image: "12thlevel.png",
        colour: "#00ffff",
        width: 100,
        height: 60,
        class: "",
      },
      {
        name: "NW Natural",
        description: `NW Natural delivers natural gas safely and reliably to customers 
        in Oregon and Southwest Washington in the United States. Corbett Technologies 
        provided software and consulting 
        services during the migration of an externally hosted mainframe system onto an
        in-house system. This resulted in significant on-going financial savings.`,
        url: "https://www.nwnatural.com/",
        image: "nwnatural.jpg",
        colour: "rgb(34, 83, 122)",
        width: 220,
        height: 50,
        class: "pageBreak",
      },
      {
        name: "Evoqua Water Technologies",
        description: `We designed and implemented a solution to make important data
        from a prior legacy enterprise system available for inquiry. This eliminated the 
        cost of maintaining expensive, obsolete hardware.`,
        url: "https://www.evoqua.com",
        image: "evoqua.png",
        colour: "#666666",
        width: 220,
        height: 60,
        class: "pageBreak",
      },
      {
        name: "Innoxa",
        description: `An iconic name in the Australian cosmetic industry,
        Corbett Technologies supported and enhanced their manufacturing, 
        distribution and accounting systems for over thirteen years.`,
        url: "https://innoxa.com.au",
        image: "innoxa.png",
        colour: "#f9dbc1",
        width: 220,
        height: 40,
        class: "",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.customers {
  padding-bottom: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: $breakpoint-tablet) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: $breakpoint-phone) {
  .grid {
    grid-template-columns: 1fr;
    max-width: 410px;
  }
}

.fontLarge {
  text-align: center;
  padding-top: 1rem;
}

@media screen and (max-width: $breakpoint-phone) {
  .fontLarge {
    margin-bottom: 0.5rem;
  }
}

.details {
  text-align: center;
  margin: 0 2rem 1rem 2rem;
}
</style>
