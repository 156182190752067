<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary class="noprint" :width="nav_width">
      <SideMenu @close-drawer="drawer = false" />
    </v-navigation-drawer>
    <v-main>
      <v-fade-transition mode="out-in">
        <div>
          <div class="noprint">
            <v-btn class="iconBtn" v-show="!drawer" v-on:click.stop="showMenu()" aria-label="Open side menu button">
              <v-icon icon="mdi-backburger" :color="darkTextColour" size="large" class="hamburger"></v-icon>
            </v-btn>
          </div>
          <MainPage :scroll="scroll" :id="'page1'" />
          <MainPage :scroll="scroll" :id="'first'" />
          <MainPage :scroll="scroll" :id="'page2'" />
          <MainPage :scroll="scroll" :id="'page3'" />
          <LastText />
          <CookieToast class="noprint" />
        </div>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import MainPage from "./components/MainPage.vue";
import LastText from "./components/LastText.vue";
import SideMenu from "@/components/SideMenu.vue";
import CookieToast from "@/components/CookieToast.vue";

export default {
  name: "App",

  components: {
    CookieToast,
    MainPage,
    LastText,
    SideMenu,
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // console.log("App Scrolled " + window.pageYOffset);
      this.scroll = Math.max(window.pageYOffset, 0);
    },
    showMenu() {
      this.drawer = true;
    },
  },
  computed: {
    nav_width() {
      return window.innerWidth > 375 ? 375 : window.innerWidth * 0.9;
    },
  },
  data: () => ({
    drawer: false,
    scroll: 0,
    darkTextColour: "#032a79",
  }),
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

body {
  font-family: Roboto, sans-serif;
  font-size: larger;
  max-width: 100%;
  overflow-x: hidden;
}

nav {
  width: 14rem;
}
</style>

<style scoped lang="sass">
.iconBtn
  top: 1rem
  left: 1rem
  position: fixed
  opacity: 0.8
  z-index: 8

.hamburger
  transform: scale(2) rotate(180deg)
  margin-bottom: 1px
</style>
