<template>
  <div id="about" style="padding-top: 2px">
    <div class="textPage solidBg">
      <h2 class="fontLarge">About Us</h2>
      <div class="textContent">
        <div class="fontMedium"></div>
        <p class="details">
          Corbett Technologies provides software development and support,
          database design, systems integration and web programming services to
          Australian government and businesses of all sizes.
        </p>
        <p class="details">
          Leveraging our experience with a wide range of technologies, software
          languages, databases and operating systems, we strive to provide cost
          effective solutions to solve our customers' requirements.
        </p>
        <p class="details">
          Whether front end, server side, full stack, desktop or mobile we have
          the experience and track record of completing projects on time and on
          budget.
        </p>
        <p class="details">
          <a href="#contactUs">Contact us</a> to see how we can help you make
          your next project a success.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.fontLarge {
  text-align: center;
  padding-top: 0;
  line-height: 1.2;
}

.fontMedium {
  color: $menuColour;
}

@media screen {
  a:link {
    color: inherit;
  }

  a:visited {
    color: inherit;
  }
}
@media print {
  a:link {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    text-decoration: none;
    color: inherit;
  }
}
</style>
