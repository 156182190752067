<template>
  <div>
    <div class="company noprint" ref="company">
      <h1 class="word fontLarge" ref="wordOne">Corbett Technologies</h1>
      <div class="word fontSmall" ref="topTagLine2">Systems Integration</div>
      <div class="word fontSmall" ref="topTagLine1">Software Development</div>
      <div class="word fontSmall" ref="lowerTagLine2">Fullstack Solutions</div>
      <div class="word fontSmall" ref="lowerTagLine1">
        Data Design &amp; Analysis
      </div>
    </div>
    <div class="printOnly">
      <div class="fontSmall">Systems Integration</div>
      <div class="fontSmall">Software Development</div>
      <div class="fontLarge">Corbett Technologies</div>
      <div class="fontSmall">Data Design &amp; Analysis</div>
      <div class="fontSmall">Fullstack Solutions</div>
    </div>
    <v-snackbar
      class="toast"
      location="bottom right"
      transition="v-dialog-bottom-transition"
      content-class="toast noprint"
      v-if="toastShowing"
      v-model="toastShowing"
      timeout="-1"
    >
      &#8595; More &#8595;
    </v-snackbar>
  </div>
</template>

<script>
import { findScrollBarSize } from "../utils/utilities";

const timerInterval = 1100;

export default {
  components: {},
  props: ["id", "height", "width", "scroll"],
  mounted() {
    this.scrollBarSize = findScrollBarSize(document);
    this.centreText();
    window.setTimeout(this.showToast, timerInterval);
  },
  methods: {
    getComponentBottom() {
      const thisComponent = this.$refs.company;

      if (!thisComponent) {
        return 0;
      }
      return thisComponent.parentElement.offsetTop + thisComponent.scrollHeight;
    },
    getComponentTop() {
      const thisComponent = this.$refs.company;

      if (!thisComponent) {
        return 0;
      }
      return thisComponent.parentElement.offsetTop;
    },
    log(message, object) {
      if (this.id === "page1" && process.env.NODE_ENV === "development") {
        if (object != undefined && object != null) {
          // eslint-disable-next-line
          console.log(this.id + ": " + message, object);
        } else {
          // eslint-disable-next-line
          console.log(this.id + " " + this._uid + ": " + message);
        }
      }
    },
    positionWords() {
      if (this.height == 0) {
        // this.log("Company We're still loading!");
        return;
      }
      const thisComponent = this.$refs.company;

      if (!thisComponent) {
        return;
      }
      const componentTop = this.getComponentTop();
      const componentBottom = this.getComponentBottom();

      if (
        componentTop > this.scroll + this.height ||
        componentBottom < this.scroll
      ) {
        thisComponent.style.visibility = "hidden";
        return;
      }
      thisComponent.style.visibility = "visible";

      const posnY = Math.max(this.scroll - componentTop, 0);
      const padding = 8;

      const wordOne = this.$refs.wordOne;
      const topTagLine1 = this.$refs.topTagLine1;
      const topTagLine2 = this.$refs.topTagLine2;
      const lowerTagLine1 = this.$refs.lowerTagLine1;
      const lowerTagLine2 = this.$refs.lowerTagLine2;

      var wordOneTop = Math.round((this.height - wordOne.offsetHeight) * 0.5);

      this.pcnt = Math.min(posnY / (thisComponent.scrollHeight * 0.3), 1);
      const bottomMargin = 0;

      const topTagLine1Finish = wordOneTop - topTagLine1.offsetHeight;
      const topLine1Top =
        Math.round((topTagLine1Finish - padding) * this.pcnt) + padding;

      const tagGap =
        Math.round((1 - this.pcnt) * topTagLine1.offsetHeight * 4) + padding;

      const topLine2Top = topLine1Top - topTagLine1.offsetHeight - tagGap;

      // Lower tag lines
      const diff = wordOneTop - topLine1Top - topTagLine1.offsetHeight;
      const lowerLine1Top = wordOneTop + wordOne.offsetHeight + diff;
      const lowerLine2Top = lowerLine1Top + lowerTagLine1.offsetHeight + tagGap;

      var adjustment = 0;
      const absComponentBottom = componentBottom - this.scroll;
      if (
        (absComponentBottom < this.height) &
        (lowerLine2Top + lowerTagLine2.offsetHeight + bottomMargin >
          absComponentBottom)
      ) {
        adjustment =
          lowerLine2Top +
          lowerTagLine2.offsetHeight +
          bottomMargin -
          (componentBottom - this.scroll);
      }

      wordOne.style.top = wordOneTop - adjustment + "px";
      topTagLine1.style.top = topLine1Top - adjustment + "px";
      topTagLine2.style.top = topLine2Top - adjustment + "px";
      lowerTagLine1.style.top = lowerLine1Top - adjustment + "px";
      lowerTagLine2.style.top = lowerLine2Top - adjustment + "px";
    },
    centreText() {
      this.$refs.topTagLine1.style.width = this.width + "px";
      this.$refs.topTagLine2.style.width = this.width + "px";
      this.$refs.wordOne.style.width = this.width + "px";
      this.$refs.lowerTagLine1.style.width = this.width + "px";
      this.$refs.lowerTagLine2.style.width = this.width + "px";
    },
    showToast() {
      if (this.toastShowing) {
        this.toastShowing = false;
        this.timer = window.setTimeout(this.showToast, timerInterval);
      } else if (this.scroll < this.getComponentBottom()) {
        this.toastShowing = true;
        this.timer = window.setTimeout(this.showToast, timerInterval);
      }
    },
    scrolledUp() {
      return this.scroll > 100;
    },
    stopToasting() {
      clearInterval(this.timer);
      this.toastShowing = false;
    },
  },
  watch: {
    height: function () {
      this.positionWords();
    },
    scroll: function () {
      this.positionWords();
      if (this.scrolledUp()) {
        this.stopToasting();
      }
    },
    width: function () {
      this.centreText();
      this.positionWords();
    },
  },
  data: () => ({
    pcnt: 0,
    scrollBarSize: 0,
    toastShowing: false,
    timer: null,
  }),
};
</script>

<style lang="scss">
.word {
  position: fixed;
  text-align: center;
}

.company {
  height: 130vh;
  z-index: 1;
}

.toast {
  margin-right: 1rem;
  z-index: 50;
}
</style>

<style scoped>
:deep(.v-snackbar__content) {
  text-align: center;
}

:deep(.v-snackbar__wrapper) {
  min-width: 7rem;
}
</style>
