var scrollBarWidth = 0;

export const findScrollBarSize = (document) => {
  if (scrollBarWidth == 0) {
    const hiddenDiv = document.createElement("div");
    hiddenDiv.style.visibility = "hidden";
    // force a scrollbar
    hiddenDiv.style.overflow = "scroll";
    hiddenDiv.style.msOverflowStyle = "scrollbar"; // for WinJS apps
    document.body.appendChild(hiddenDiv);

    const innerDiv = document.createElement("div");
    hiddenDiv.appendChild(innerDiv);
    scrollBarWidth = hiddenDiv.offsetWidth - innerDiv.offsetWidth;
    document.body.removeChild(hiddenDiv);
  }

  return scrollBarWidth;
};

export const breakpoints = { phone: 440, tablet: 768, desktop: 1024 };
