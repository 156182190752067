<template>
  <div v-if="showing" class="cookieText" ref="snackbar">
    <div class="pt-2">
      <p>
        This site uses cookies from Google to analyze site usage. If you
        disagree with this, please close this page and clear your browser
        history and cookies.
      </p>
      <p>Thanks anyway for visiting us.</p>

      <v-btn
        type="button"
        theme="light"
        block
        rounded="lg"
        @click.stop="showing = false"
        aria-label="Close Cookie Toast Button"
      >
        Close
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const snackbar = this.$refs.snackbar;
      if (window.outerWidth && !!snackbar && snackbar.clientWidth) {
        const hCentered =
          (window.innerWidth - this.$refs.snackbar.clientWidth) / 2 + "px";
        const vCentered =
          (window.innerHeight - this.$refs.snackbar.clientHeight) / 2 + "px";
        const vBottom =
          window.innerHeight - this.$refs.snackbar.clientHeight - 6 + "px";
        if (window.outerHeight < 500) {
          this.location = "center center";
          this.left = hCentered;
          this.top = vCentered;
        } else if (window.outerWidth < 760) {
          this.left = hCentered;
          this.top = vBottom;
        } else {
          this.location = "bottom left";
          this.left = "1rem";
          this.top = vBottom;
        }
      }
    },
  },
  data: () => ({
    showing: true,
    isVertical: true,
    location: "",
    left: "",
    top: "",
  }),
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

.v-btn__content {
  color: #424242;
}

p {
  margin-bottom: 1rem;
}

.cookieText {
  position: fixed;
  left: v-bind(left);
  top: v-bind(top);
  background-color: $charcoal;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 6px #424242;
  color: whitesmoke;
  font-size: smaller;
  padding: 1rem;
  width: 400px;
  max-width: 95vw;
  z-index: 2001;
}
</style>
