<template>
  <div id="contactUs" class="contact solidBg textContent noprint" ref="contact">
    <div class="both" ref="both">
      <h2 class="contactText float-left fontLarge" ref="left">Contact Us</h2>
      <div class="contactText float-right fontLarge" ref="right">
        Contact Us
      </div>
    </div>
    <div class="form card" ref="formCard">
      <v-form
        name="CorbTech-comment"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref="form"
        :model="formValid"
      >
        <input type="hidden" name="form-name" value="CorbTech-comment" />
        <v-text-field
          label="Your Name"
          name="name"
          clearable
          variant="solo"
          :rules="[rules.required]"
          class="required"
        ></v-text-field>
        <v-text-field
          label="Your E-mail Address"
          name="email"
          clearable
          variant="solo"
          :rules="[rules.required]"
          class="required"
        ></v-text-field>
        <v-textarea
          label="Comments"
          name="comments"
          clearable
          variant="solo"
          no-resize
          :rules="[rules.required]"
          class="required"
        ></v-textarea>
        <v-btn
          block
          rounded
          type="submit"
          class="button__full mb-4"
          @click="onSubmit($event)"
          ref="submit"
          aria-label="Submit Button"
          :disabled="!formValid"
          >Submit</v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
import { breakpoints, findScrollBarSize } from "../utils/utilities";

export default {
  props: ["id", "height", "width", "scroll"],
  mounted() {
    this.fullWidth = window.outerWidth;
    this.scrollBarSize = findScrollBarSize(document);

    setTimeout(this.positionWords(), 10);
  },
  methods: {
    log(message, object) {
      if (this.id == "page3" && process.env.NODE_ENV === "development") {
        if (object != undefined && object != null) {
          // eslint-disable-next-line
          console.log(this.id + ": " + message, object);
        } else {
          // eslint-disable-next-line
          console.log(this.id + ": " + message);
        }
      }
    },
    positionWords() {
      if (this.height == 0) {
        //this.log("We're still loading!");
        return;
      }

      const thisComponent = this.$refs.contact;
      const both = this.$refs.both;
      const left = this.$refs.left;
      const right = this.$refs.right;
      const formCard = this.$refs.formCard;
      const componentTop = thisComponent.parentElement.offsetTop;
      const componentBottom = componentTop + thisComponent.offsetHeight;

      if (
        componentTop > this.scroll + this.height ||
        componentBottom < this.scroll
      ) {
        //this.log("We're not visible!");
        return;
      }

      const scrollAdjust = Math.round(this.height * 0.7);
      const posnY = Math.max(this.scroll + scrollAdjust - componentTop, 0);
      this.pcnt = Math.min((posnY / thisComponent.offsetHeight) * 2.5, 1);
      const topPadding = 10;

      var posTopMax;
      if (this.pcnt == 1) {
        both.style.top = topPadding + "px";
      } else {
        posTopMax = thisComponent.offsetHeight - left.offsetHeight;
        // this.log("posTopMax: " + posTopMax);
        both.style.top =
          Math.min(Math.max(posnY - scrollAdjust, topPadding), posTopMax) +
          "px";
      }

      const angle = Math.max(90 - this.pcnt * 90, 0);
      const radians = angle * (Math.PI / 180);
      const sine = Math.sin(radians);
      const cos = Math.cos(radians);
      const posTop = Math.round(sine * left.scrollWidth * 0.5);
      const posLeft = Math.round(
        sine * left.scrollWidth * -0.5 + left.scrollHeight * 0.5
      );

      var offset = Math.round(thisComponent.clientWidth * 0.5 * this.pcnt);
      if (this.width < breakpoints.phone) {
        offset += 40;
      }

      left.style.transform = "rotate(-" + angle + "deg)";
      left.style.top = posTop + "px";
      const computedStyle = window.getComputedStyle(thisComponent);
      const padding =
        parseInt(computedStyle.getPropertyValue("padding-left")) +
        parseInt(computedStyle.getPropertyValue("padding-right"));
      const finalLeft = Math.round(
        (thisComponent.clientWidth - left.offsetWidth - padding) * 0.5
      );
      left.style.left =
        Math.min(
          posLeft + offset - Math.round(cos * left.offsetWidth * 0.5),
          finalLeft
        ) + "px";

      right.style.transform = "rotate(" + angle + "deg)  scale(1, -1)";
      right.style.top =
        posTop + Math.round(cos * left.scrollHeight * 0.65) + "px";
      //right.style.top = posTop + "px";
      right.style.left =
        Math.max(
          Math.round(
            thisComponent.clientWidth -
              padding -
              sine * right.scrollHeight * 2 -
              cos * right.scrollWidth
          ) - offset,
          finalLeft
        ) + "px";
      right.style.opacity = sine;

      formCard.style.top = left.offsetTop + left.offsetHeight + "px";
      const opacity = Math.pow(cos, 10);
      formCard.style.opacity = opacity;
      var scale = 0.1;
      if (this.pcnt > 0.1) {
        scale = opacity;
      }
      formCard.style.transform = `scale(${scale}, ${scale}) rotate(${
        sine * 360
      }deg)`;
    },
    onSubmit(event) {
      const isValid = this.$refs.form.validate();
      // this.log("Form is valid: " + result);
      if (!isValid) {
        event.preventDefault();
      }
    },
  },
  watch: {
    height: function () {
      this.positionWords();
    },
    scroll: function () {
      this.positionWords();
    },
    width: function () {
      this.positionWords();
    },
  },
  data: () => ({
    pcnt: 0,
    scrollBarSize: 0,
    fullWidth: 0,
    savedHeight: 0,
    rules: {
      required: (value) => !!value || "Required.",
    },
    formValid: true,
  }),
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

.contact {
  position: relative;
  background: $mainColour;
  overflow: hidden;
  height: 660px;
  margin: 60px auto 60px auto;
}

.contactText {
  position: absolute;
  display: inline-block;
}

.form {
  position: relative;
  display: block;
  width: 100%;
  max-width: 600px;
  height: 420px;
  padding: 2rem;
  opacity: 0.3;
  transform-origin: 50% 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: white !important;
}

@media screen and (max-width: $breakpoint-tablet) {
  .form {
    padding: 6px;
  }
}

@media screen and (max-height: $breakpoint-phone) {
  .form {
    height: 450px;
  }
}

@media screen and (max-width: $breakpoint-phone) {
  .contact {
    height: 550px;
  }

  .form {
    width: 90%;
  }
}

.left {
  z-index: 2;
}

.required label::after {
  content: "*";
  color: red;
}

.right {
  z-index: 1;
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.both {
  position: absolute;
  display: block;
  width: 140vw;
  margin-left: auto;
  margin-right: auto;
}

.v-label.v-field-label {
  color: rgba(0, 0, 0, 0.9);
}

.v-field__input {
  font-size: 0.8rem;
}
</style>
